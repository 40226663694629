<template>
  <Swiper
    :modules="[Navigation, Pagination, A11y, Autoplay]"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 6000 }"
    class="h-[600px]"
  >
    <SwiperSlide
      v-for="slide in slides"
      :key="slide.id"
      class="relative h-[600px] w-full"
    >
      <!-- Image -->
      <NuxtImg
        :src="getCmsImage(slide.image)"
        format="webp"
        loading="lazy"
        alt=""
        class="h-full w-full object-cover"
      />
      
      <!-- Overlay Content -->
      <div class="absolute inset-0 pl-12 flex items-center bg-black/40">
        <v-container>
          <div class="text-white mx-auto w-full">
            <h2 class="text-3xl sm:text-5xl font-bold mb-4 sm:mb-6 max-w-4xl">
              {{ slide.heading }}
            </h2>
            <p class="text-white text-lg sm:text-xl max-w-lg mt-2 sm:mt-4">
              {{ slide.subheading }}
            </p>
            
            <div v-for="cta in slide.callToAction" :key="cta.url" class="mt-4">
              <NuxtLink class="btn btn-primary" :to="cta.url" v-if="cta.text">
                {{ cta.text }}
              </NuxtLink>
            </div>
          </div>
        </v-container>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts" setup>
import { getCmsImage } from '@/composables/useCms';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';

interface Slide {
  id: string;
  heading: string;
  subheading: string;
  image: {
    title: string;
    url: string;
  }[];
  callToAction: {
    text: string;
    customText: string;
    url: string;
  };
}

withDefaults(
  defineProps<{
    heading?: string | null;
    slides: Slide[];
  }>(),
  {
    slides: () => [],
  },
);

const localePath = useLocalePath();
</script>

<style scoped>
/* Hide navigation arrows on mobile screens */
@media (max-width: 640px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}
</style>
